@keyframes HighlightPulse {
  0%,
  100% {
    filter: drop-shadow(0 0 0.3rem $hover-color);
  }
  50% {
    filter: drop-shadow(0 0 0.7rem $hover-color);
  }
}

.highlight-pulse {
  animation: 1.5s linear 0s infinite HighlightPulse;

  // box-shadow: 0rem 0rem 0.1rem 0.1rem rgba(255, 215, 39, 0.75);
}
