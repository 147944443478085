.instruction-list {
  font-size: 2rem;
  grid-column: 1/4;
  list-style: decimal;
  list-style-position: inside;
  margin: 0 10rem;

  @media only screen and (max-width: $media-screen-lg) {
    margin: 0 1rem;
  }

  @media only screen and (max-width: $media-screen-sm) {
    font-size: 1.4rem;
    margin: 0;
  }

  & li {
    margin-bottom: 1rem;
    @media only screen and (max-width: $media-screen-md) {
      margin-left: 1rem;
    }

    & > div {
      display: inline;
    }
  }

  & > div {
    padding: 1rem 0;
  }
}
