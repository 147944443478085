@font-face {
  font-family: "Jost";
  src: url("../assets/fonts/Jost-400-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Jost";
  src: url("../assets/fonts/Jost-700-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("../assets/fonts/InterVariable.woff2?v=4.0") format("woff2");
}
@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("../assets/fonts/InterVariable-Italic.woff2?v=4.0") format("woff2");
}
