.stack {
  display: block;
  height: 20rem;

  @media only screen and (max-width: $media-screen-md) {
    height: 10rem;
  }

  @media only screen and (max-width: $media-screen-sm) {
    height: 10rem;
  }
}

@keyframes SymbolTransition {
  5%,
  95% {
    transform: scale(1);
  }
  0%,
  100% {
    transform: scale(0.01);
  }
}

.reset-main {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.stack-display {
  &.rotate {
    animation: 1.5s linear 0s infinite SymbolTransition;
  }
}

.statue-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5rem;

  & > :nth-child(2) {
    order: 3;
    grid-column-start: 3;
    grid-column-end: 1;
  }

  @media only screen and (max-width: $media-screen-sm) {
    margin-top: 1rem;
  }
}

.statue-wrapper {
  display: grid;
  justify-items: center;
  justify-content: center;
  position: relative;
  row-gap: 0.5rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &.triangle-statue span.player-marker {
    position: absolute;
    top: 50%;
  }

  & .player-marker {
    color: white;
  }

  & > button {
    margin-top: 2rem;
  }
}

.knight-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  & > div {
    position: relative;
  }
}

.ogre-option {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.escape-option {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.simulator-data {
  position: absolute;
  z-index: 1;
  right: 0;
}

@keyframes FinalizedTransition {
  0% {
    background-color: rgba(221, 40, 40, 0);
  }
  100% {
    background-color: rgba(10, 10, 10, 0.95);
  }
}

.finalized-overlay {
  color: white;
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  top: 0;

  & span {
    text-align: center;
  }

  animation: 2.5s linear 0s 1 normal forwards running FinalizedTransition;
}

.success-data {
  text-align: center;
}

.tutorial-option {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  bottom: 1rem;
  right: 1rem;
  font-size: 1.2rem;
  border: solid 1px white;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    color: $hover-color;
    border-color: $hover-color;
    transition: all 0.2s ease-in-out;
  }
}

.tutorial-wrapper {
  padding: 2rem;
  border: solid 1px white;
  position: relative;
  max-width: 116rem;
  margin: 0 auto 2rem;

  & h2 {
    text-align: center;
  }

  & ul {
    list-style: circle inside;
  }
}

.tutorial-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tutorial-progress {
  width: 80%;
  text-align: center;
}

.prev-step {
  width: 10%;
}

.next-step {
  width: 10%;
}

.close-tutorial {
  @extend .default-filter;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@keyframes PointerArrowBounceTop {
  0%,
  100% {
    top: 3.5rem;
  }
  50% {
    top: 4.5rem;
  }
}

@keyframes PointerArrowBounceBottom {
  0%,
  100% {
    top: -2rem;
  }
  50% {
    top: -1rem;
  }
}

@keyframes PointerArrowBounceLeft {
  0%,
  100% {
    left: 2rem;
  }
  50% {
    left: 1rem;
  }
}

@keyframes PointerArrowBounceRight {
  0%,
  100% {
    left: -2rem;
  }
  50% {
    left: -1rem;
  }
}

@keyframes PointerArrowBounceBottomAbs {
  0%,
  100% {
    top: -5.5rem;
  }
  50% {
    top: -6.5rem;
  }
}

.tutorial-pointer-arrow {
  background-color: red;

  &.absolute {
    position: absolute;
  }

  &.relative {
    position: relative;
  }

  &.center {
    margin: 0 auto;
  }

  &.left {
    width: 3.5rem;
    height: 2.5rem;
    left: 4rem;
    clip-path: polygon(
      40% 0%,
      40% 20%,
      100% 20%,
      100% 80%,
      40% 80%,
      40% 100%,
      0% 50%
    );

    animation: 1.5s ease-in-out 0s infinite PointerArrowBounceLeft;
  }

  &.up {
    height: 3.5rem;
    width: 2.5rem;
    clip-path: polygon(
      20% 100%,
      20% 40%,
      0 40%,
      50% 0,
      100% 40%,
      80% 40%,
      80% 100%
    );

    animation: 1.5s ease-in-out 0s infinite PointerArrowBounceTop;
  }

  &.right {
    width: 3.5rem;
    height: 2.5rem;
    right: 4rem;
    clip-path: polygon(
      0% 20%,
      60% 20%,
      60% 0%,
      100% 50%,
      60% 100%,
      60% 80%,
      0% 80%
    );

    animation: 1.5s ease-in-out 0s infinite PointerArrowBounceRight;
  }

  &.down {
    height: 3.5rem;
    width: 2.5rem;
    clip-path: polygon(
      20% 0,
      20% 60%,
      0 60%,
      50% 100%,
      100% 60%,
      80% 60%,
      80% 0
    );

    animation: 1.5s ease-in-out 0s infinite PointerArrowBounceBottom;

    &.absolute {
      animation: 1.5s ease-in-out 0s infinite PointerArrowBounceBottomAbs;
    }
  }

  &.inline {
    position: relative;
    display: inline-block;
    animation: none;
  }
}

.input-history {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 27.5%;
  height: 14rem;
  overflow-y: scroll;
  text-align: right;
  direction: rtl;

  @media only screen and (max-width: $media-screen-lg) {
    position: relative;
    margin-top: 2rem;
    direction: ltr;
    text-align: center;
    width: 100%;
  }

  & .input-row {
    display: flex;
    align-items: center;
    justify-content: end;
    direction: ltr;

    &:hover {
      cursor: pointer;
    }

    &.green {
      color: #008000;
    }

    &.yellow {
      color: #ffff00;
    }

    &.red {
      color: #ff0000;
    }
  }
  & img {
    width: 2rem;
    height: 2rem;

    &.input-success-state-icon {
      margin-left: 1rem;
    }

    &.input-replay-icon {
      margin-right: 1rem;
    }

    &.red {
      filter: invert(15%) sepia(36%) saturate(5098%) hue-rotate(348deg)
        brightness(99%) contrast(121%);
    }

    &.green {
      filter: invert(31%) sepia(98%) saturate(1257%) hue-rotate(93deg)
        brightness(99%) contrast(106%);
    }

    &.yellow {
      filter: invert(89%) sepia(93%) saturate(6585%) hue-rotate(358deg)
        brightness(106%) contrast(107%);
    }
  }
}

@mixin triangleSize($size) {
  $isocSize: $size;
  $isocBSize: sqrt(pow($isocSize, 2) + pow($isocSize, 2));
  $isocSizeUnit: $isocSize + unquote("rem");
  $isocBSizeUnit: $isocBSize + unquote("rem");
  width: 0rem;
  height: 0rem;
  border-style: solid;
  border-width: 0 $isocSizeUnit $isocBSizeUnit $isocSizeUnit;
  border-color: transparent transparent black transparent;
  margin: 0 auto;
  line-height: $isocBSizeUnit;

  $isocInnerSize: $isocSize - 1;
  $isocInnerBSize: sqrt(pow($isocInnerSize, 2) + pow($isocInnerSize, 2));
  $isocInnerSizeUnit: $isocInnerSize + unquote("rem");
  $isocInnerBSizeUnit: $isocInnerBSize + unquote("rem");
}

.input-history-overlay {
  position: absolute;
  display: flex;

  & .overlay-symbol {
    width: 8rem;
    height: 8rem;

    @media only screen and (max-width: $media-screen-md) {
      width: 3rem;
      height: 3rem;
    }

    @media (prefers-color-scheme: dark) {
      filter: invert(0.5) sepia(80) saturate(0) hue-rotate(15deg);
    }
  }

  &.from-left {
    &.to-middle {
      margin-left: 35%;
      margin-top: 10%;
    }

    &.to-right {
      margin-left: 35%;
      margin-top: 4%;
    }
  }

  &.from-middle {
    &.to-left {
      margin-left: 35%;
      margin-top: 10%;
    }

    &.to-right {
      margin-left: 60%;
      margin-top: 10%;
    }
  }

  &.from-right {
    &.to-left {
      margin-left: 60%;
      margin-top: 4%;
    }

    &.to-middle {
      margin-left: 60%;
      margin-top: 10%;
    }
  }
}

.overlay-pointer-arrow {
  background-color: yellow;
  height: 4.5rem;
  width: 6.5rem;
  position: absolute;
  margin: 0 auto;
  bottom: -6rem;
  clip-path: polygon(
    40% 0%,
    40% 20%,
    100% 20%,
    100% 80%,
    40% 80%,
    40% 100%,
    0% 50%
  );

  @media only screen and (max-width: $media-screen-md) {
    bottom: -4rem;
    height: 3.5rem;
    width: 5rem;
  }

  &.from-left {
    &.to-middle {
      transform: rotate(215deg);
    }

    &.to-right {
      transform: rotate(180deg);
    }
  }

  &.from-middle {
    &.to-left {
      transform: rotate(45deg);
    }

    &.to-right {
      transform: rotate(135deg);
    }
  }

  &.from-right {
    &.to-left {
      transform: rotate(0deg);
    }

    &.to-middle {
      transform: rotate(-45deg);
    }
  }
}

.overlay-success-state {
  position: absolute;
  width: 3rem;
  height: 3rem;
  left: calc(50% - 1.5rem);
  bottom: 100%;
  &.red {
    filter: invert(15%) sepia(36%) saturate(5098%) hue-rotate(348deg)
      brightness(99%) contrast(121%);
  }

  &.green {
    filter: invert(31%) sepia(98%) saturate(1257%) hue-rotate(93deg)
      brightness(99%) contrast(106%);
  }
}

.simulator-checklist {
  max-width: 100rem;
  margin: 1rem auto;
  position: static;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: $media-screen-sm) {
    max-width: 50rem;
  }

  @media only screen and (max-width: $media-screen-x-sm) {
    margin: 1rem;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    & > span {
      text-align: center;
    }
  }

  & img {
    width: 2rem;
    height: 2rem;

    &.red {
      filter: invert(15%) sepia(36%) saturate(5098%) hue-rotate(348deg)
        brightness(99%) contrast(121%);
    }

    &.green {
      filter: invert(31%) sepia(98%) saturate(1257%) hue-rotate(93deg)
        brightness(99%) contrast(106%);
    }
  }
}

.mode-notification {
  text-align: center;
}
