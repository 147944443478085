.mt-10 {
  margin-top: 1rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.ml-10 {
  margin-left: 1rem;
}

.mr-10 {
  margin-right: 1rem;
}
