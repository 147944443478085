.symbol-wrapper {
  display: grid;
  justify-items: center;

  &.inline {
    display: inline;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;

    & span {
      margin-right: 0.5rem;
    }
  }
}

img.symbol {
  width: 5rem;
  height: 5rem;

  &:not(.final) {
    @media (prefers-color-scheme: dark) {
      filter: invert(0.5) sepia(80) saturate(0) hue-rotate(15deg);
    }

    &.disabled {
      @extend .disabled;
    }
  }

  @media only screen and (max-width: $media-screen-md) {
    width: 3rem;
    height: 3rem;
  }

  &.inline {
    width: 2.6rem;
    height: 2.6rem;
    padding: 0 0.5rem;

    @media only screen and (max-width: $media-screen-lg) {
      width: 2.6rem;
      height: 2.6rem;
    }

    @media only screen and (max-width: $media-screen-md) {
      width: 1.6rem;
      height: 1.6rem;
      padding-top: 0.5rem;
    }

    @media only screen and (max-width: $media-screen-sm) {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &.selected {
    @extend .selected;
  }

  &.disabled {
    @extend .disabled;
  }
}

.text {
  margin-top: 1rem;

  &.disabled {
    color: #444;
  }
}

.hide-symbol + .text {
  border: solid 0.2rem lightgrey;
  padding: 1rem;
  margin: 0 auto;
  width: 100%;
}

@mixin triangleSize($size) {
  $isocSize: $size;
  $isocBSize: sqrt(pow($isocSize, 2) + pow($isocSize, 2));
  $isocSizeUnit: $isocSize + unquote("rem");
  $isocBSizeUnit: $isocBSize + unquote("rem");
  width: 0rem;
  height: 0rem;
  border-style: solid;
  border-width: 0 $isocSizeUnit $isocBSizeUnit $isocSizeUnit;
  border-color: transparent transparent black transparent;
  margin: 0 auto;
  line-height: $isocBSizeUnit;

  $isocInnerSize: $isocSize - 1;
  $isocInnerBSize: sqrt(pow($isocInnerSize, 2) + pow($isocInnerSize, 2));
  $isocInnerSizeUnit: $isocInnerSize + unquote("rem");
  $isocInnerBSizeUnit: $isocInnerBSize + unquote("rem");
}

@mixin squareSize($size) {
  $squareSize: $size;
  width: 0rem;
  height: 0rem;
  border-style: solid;
  border-width: $squareSize;
  border-color: black;
  margin: 0 auto;
}

@mixin circleSize($size) {
  $circleSize: $size;
  width: 0rem;
  height: 0rem;
  border-style: solid;
  border-width: $circleSize;
  border-color: black;
  border-radius: 50%;
  margin: 0 auto;
}

.triangle {
  @include triangleSize(16);

  @media only screen and (max-width: $media-screen-lg) {
    @include triangleSize(12);
  }

  @media only screen and (max-width: $media-screen-md) {
    @include triangleSize(6);
  }

  &.small {
    @include triangleSize(8);

    @media only screen and (max-width: $media-screen-lg) {
      @include triangleSize(6);
    }

    @media only screen and (max-width: $media-screen-md) {
      @include triangleSize(4);
    }
  }

  &.tiny {
    @include triangleSize(4);

    @media only screen and (max-width: $media-screen-lg) {
      @include triangleSize(2);
    }
  }
}

.square {
  @include squareSize(10rem);

  @media only screen and (max-width: $media-screen-lg) {
    @include squareSize(8rem);
  }

  @media only screen and (max-width: $media-screen-md) {
    @include squareSize(5rem);
  }

  &.small {
    @include squareSize(8rem);

    @media only screen and (max-width: $media-screen-lg) {
      @include squareSize(5rem);
    }

    @media only screen and (max-width: $media-screen-md) {
      @include squareSize(4rem);
    }
  }

  &.tiny {
    @include squareSize(4rem);

    @media only screen and (max-width: $media-screen-lg) {
      @include squareSize(2rem);
    }
  }
}

.circle {
  @include circleSize(10rem);

  @media only screen and (max-width: $media-screen-lg) {
    @include circleSize(8rem);
  }

  @media only screen and (max-width: $media-screen-md) {
    @include circleSize(5rem);
  }

  &.small {
    @include circleSize(8rem);

    @media only screen and (max-width: $media-screen-lg) {
      @include circleSize(5rem);
    }

    @media only screen and (max-width: $media-screen-md) {
      @include circleSize(4rem);
    }
  }

  &.tiny {
    @include circleSize(4rem);

    @media only screen and (max-width: $media-screen-lg) {
      @include circleSize(2rem);
    }
  }
}

@keyframes PendingNewShape {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(480deg);
  }
  100% {
    transform: rotate(240deg);
  }
}

.manual-highlight,
.statue-shape-symbol:hover,
.statue-symbol:hover {
  filter: drop-shadow(0 0 0.4rem $hover-color);
  cursor: pointer;
  // box-shadow: 0rem 0rem 0.1rem 0.1rem rgba(255, 215, 39, 0.75);
}

.empty-stack {
  width: 15rem;
  aspect-ratio: 1;
  margin: 0 auto;
  background-color: black;
  animation: 3s linear 0s infinite PendingNewShape;
  clip-path: polygon(
    100% 50%,
    90.57% 60.87%,
    93.3% 75%,
    79.7% 79.7%,
    75% 93.3%,
    60.87% 90.57%,
    50% 100%,
    39.13% 90.57%,
    25% 93.3%,
    20.3% 79.7%,
    6.7% 75%,
    9.43% 60.87%,
    0% 50%,
    9.43% 39.13%,
    6.7% 25%,
    20.3% 20.3%,
    25% 6.7%,
    39.13% 9.43%,
    50% 0%,
    60.87% 9.43%,
    75% 6.7%,
    79.7% 20.3%,
    93.3% 25%,
    90.57% 39.13%
  );

  @media only screen and (max-width: $media-screen-lg) {
    width: 12rem;
  }

  @media only screen and (max-width: $media-screen-md) {
    width: 8rem;
  }
}

.buff {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

.buff-icon {
  width: 4.5rem;
  height: 4.5rem;
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  background-color: #75a681;
  display: inline-block;
  position: relative;
  box-sizing: border-box;

  & + span {
    margin-left: 1rem;
  }

  & .inner {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    width: 4.1rem;
    height: 4.1rem;
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    background-color: #3f4034;
    background-repeat: no-repeat;
    background-position: center 45%;
    background-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 55%;
      height: 55%;
      position: relative;
      filter: invert(0.5) sepia(80) saturate(0) hue-rotate(15deg);
      padding-top: 0.5rem;
    }
  }
}
