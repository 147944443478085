.control-panel {
  background: white;
  border: solid 0.1rem black;
  text-align: center;
  flex-grow: 1;
  position: relative;
  margin: 1rem 0;
  left: calc(50% - 60rem);
  padding: 1rem 0;
  max-width: 120rem;

  & .options {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (max-width: $media-screen-md) {
      display: inherit;
    }
  }

  @media only screen and (max-width: $media-screen-lg-plus) {
    left: 0;
  }

  h2 {
    margin-bottom: 2rem;
  }

  @media (prefers-color-scheme: dark) {
    border-color: #444;
    background: #222;
  }

  & .control-option {
    margin-bottom: 2rem;

    & .label {
      display: inline-block;
      margin-right: 1rem;
      @media only screen and (max-width: $media-screen-x-lg) {
        margin-bottom: 1rem;
      }
    }
  }
}

.close-icon {
  @extend .default-filter;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.reset {
  grid-column: 1/4;
  position: sticky;
  top: 0;
  margin: 0 1rem 1rem 1rem;
  z-index: 1;
}
