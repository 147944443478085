html {
  font-family: "Jost", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  font-size: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  font-size: 1.6rem;

  @media (prefers-color-scheme: dark) {
    color: lightgrey;
    background: #222;
  }

  @media only screen and (max-width: $media-screen-md) {
    font-size: 1rem;
  }
}
