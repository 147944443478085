.bold {
  font-weight: 700;
}

.sup {
  font-size: 50%;
  vertical-align: super;
}

.sub {
  font-size: 50%;
  vertical-align: sub;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

a {
  color: #222;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  @media (prefers-color-scheme: dark) {
    color: lightgrey;
  }
}

a:hover,
a.active {
  color: $hover-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: InterVariable, Calibri, "Trebuchet MS", sans-serif;
}

h1 {
  font-size: 2.8rem;

  @media only screen and (max-width: $media-screen-md) {
    font-size: 1.6rem;
  }
}

h2 {
  font-size: 2rem;
  font-weight: 800;

  @media only screen and (max-width: $media-screen-md) {
    font-size: 1.6rem;
  }
}

h3 {
  font-size: 1.6rem;
  font-weight: 400;

  @media only screen and (max-width: $media-screen-md) {
    font-size: 1.2rem;
  }
}

p {
  padding: 1rem 0;
  margin: 0;
}

.main-title,
.sub-title,
.step-title,
.select-title {
  font-family: InterVariable, Calibri, "Trebuchet MS", sans-serif;
}
