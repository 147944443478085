html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
}

@font-face {
  font-family: Jost;
  src: url("Jost-400-Book.330b853b.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Jost;
  src: url("Jost-700-Bold.5b522da2.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable.e6ec8b96.woff2") format("woff2");
}

@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable-Italic.e0a5e81d.woff2") format("woff2");
}

html {
  font-family: Jost, Calibri, Trebuchet MS, sans-serif;
  font-size: 10px;
  font-weight: 400;
  overflow: hidden scroll;
}

body {
  font-size: 1.6rem;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #d3d3d3;
    background: #222;
  }
}

@media only screen and (width <= 768px) {
  body {
    font-size: 1rem;
  }
}

.mt-10 {
  margin-top: 1rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.ml-10 {
  margin-left: 1rem;
}

.mr-10 {
  margin-right: 1rem;
}

.bold {
  font-weight: 700;
}

.sup {
  vertical-align: super;
  font-size: 50%;
}

.sub {
  vertical-align: sub;
  font-size: 50%;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

a {
  color: #222;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

@media (prefers-color-scheme: dark) {
  a {
    color: #d3d3d3;
  }
}

a:hover, a.active {
  color: #d37200;
}

h1, h2, h3, h4, h5, h6 {
  font-family: InterVariable, Calibri, Trebuchet MS, sans-serif;
}

h1 {
  font-size: 2.8rem;
}

@media only screen and (width <= 768px) {
  h1 {
    font-size: 1.6rem;
  }
}

h2 {
  font-size: 2rem;
  font-weight: 800;
}

@media only screen and (width <= 768px) {
  h2 {
    font-size: 1.6rem;
  }
}

h3 {
  font-size: 1.6rem;
  font-weight: 400;
}

@media only screen and (width <= 768px) {
  h3 {
    font-size: 1.2rem;
  }
}

p {
  margin: 0;
  padding: 1rem 0;
}

.main-title, .sub-title, .step-title, .select-title {
  font-family: InterVariable, Calibri, Trebuchet MS, sans-serif;
}

button {
  cursor: pointer;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: .25rem;
  padding: .6rem 1rem;
  font-family: Jost, Calibri, Trebuchet MS, sans-serif;
}

button:hover {
  border-color: #d37200;
  transition: border-color .2s ease-in-out;
}

@media (prefers-color-scheme: dark) {
  button {
    color: #d3d3d3;
    background-color: #222;
    border-color: #444;
  }

  select {
    color: #d3d3d3;
    background-color: #444;
  }
}

header {
  grid-template-columns: 40% 60%;
  align-items: center;
  margin: 1rem;
  display: grid;
}

header menu {
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  display: flex;
}

header menu li {
  margin-left: 1rem;
}

header menu li img.symbol.inline {
  width: 2.2rem;
  height: 2.2rem;
  padding: 0;
}

header menu li img.symbol.inline:hover {
  filter: invert(.5) sepia(80) saturate(45) hue-rotate(15deg);
  transition: filter .2s ease-in-out;
}

@media only screen and (width <= 768px) {
  header menu li img.symbol.inline {
    width: 1.8rem;
    height: 1.8rem;
  }
}

header .control-panel {
  grid-column: 1 / 3;
}

.title-wrapper h1 {
  font-weight: 900;
}

.title-wrapper .atlas {
  font-style: italic;
  font-weight: 100;
}

.title-wrapper h2 {
  font-size: 1.2rem;
  font-weight: 200;
}

.control-panel {
  text-align: center;
  background: #fff;
  border: .1rem solid #000;
  flex-grow: 1;
  max-width: 120rem;
  margin: 1rem 0;
  padding: 1rem 0;
  position: relative;
  left: calc(50% - 60rem);
}

.control-panel .options {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

@media only screen and (width <= 768px) {
  .control-panel .options {
    display: inherit;
  }
}

@media only screen and (width <= 1220px) {
  .control-panel {
    left: 0;
  }
}

.control-panel h2 {
  margin-bottom: 2rem;
}

@media (prefers-color-scheme: dark) {
  .control-panel {
    background: #222;
    border-color: #444;
  }
}

.control-panel .control-option {
  margin-bottom: 2rem;
}

.control-panel .control-option .label {
  margin-right: 1rem;
  display: inline-block;
}

@media only screen and (width <= 1360px) {
  .control-panel .control-option .label {
    margin-bottom: 1rem;
  }
}

.close-icon {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.reset {
  z-index: 1;
  grid-column: 1 / 4;
  margin: 0 1rem 1rem;
  position: sticky;
  top: 0;
}

footer {
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
  display: flex;
}

.selectable {
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .default-filter, .close-tutorial, .close-icon {
    filter: invert(.5) sepia(80) saturate(0) hue-rotate(15deg);
  }
}

.default-filter:hover, .close-tutorial:hover, .close-icon:hover {
  filter: brightness(0) saturate() invert(64%) sepia(51%) saturate(7500%) hue-rotate(15deg) brightness() contrast(101%);
  transition: filter .2s ease-in-out;
}

.selected, img.symbol.selected {
  filter: invert(.5) sepia(80) saturate(45) hue-rotate(15deg);
}

@media (prefers-color-scheme: dark) {
  .selected, img.symbol.selected {
    filter: brightness(0) saturate() invert(64%) sepia(51%) saturate(7500%) hue-rotate(15deg) brightness() contrast(101%);
  }
}

.disabled, img.symbol.disabled, img.symbol:not(.final).disabled {
  cursor: not-allowed;
  filter: invert(.5) sepia(80) saturate(0) hue-rotate(15deg);
}

@media (prefers-color-scheme: dark) {
  .disabled, img.symbol.disabled, img.symbol:not(.final).disabled {
    filter: invert(.25) sepia(0) saturate(0) hue-rotate(15deg);
  }
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.final {
  filter: invert(.5) sepia(10) saturate(55) hue-rotate(185deg);
}

.hide-text, .hide-symbol {
  display: none;
}

.text-rotate {
  writing-mode: vertical-lr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 2rem);
  margin: 1rem 0;
  display: flex;
  transform: rotate(180deg);
}

.side-wrapper {
  grid-template-columns: 33% 33% 33%;
  display: grid;
}

@media only screen and (width <= 768px) {
  .side-wrapper {
    grid-template-columns: 33% 33% 33%;
  }
}

.side {
  justify-self: justify;
  border-left: .3rem solid #000;
  margin-bottom: 2rem;
}

@media (prefers-color-scheme: dark) {
  .side {
    border-color: #444;
  }
}

@media only screen and (width <= 768px) {
  .side {
    border-left-width: .1rem;
  }
}

.side:first-of-type {
  border-left: none;
}

.side-title {
  text-align: center;
  width: 100%;
  margin: 3rem 0;
}

@media only screen and (width <= 768px) {
  .side-title {
    margin: 1rem 0;
  }
}

.select-title {
  text-align: center;
  width: 100%;
  margin: 3rem 0;
}

@media only screen and (width <= 768px) {
  .select-title {
    margin: .5rem 0;
  }
}

.select {
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 1rem 0;
  min-height: 5rem;
  display: grid;
}

@media only screen and (width <= 768px) {
  .select.three {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .select.three .symbol-wrapper:nth-child(2) {
    order: 3;
    grid-column: 3 / 1;
  }

  .select.three .symbol-wrapper:nth-child(3) {
    order: 2;
  }
}

.select.six {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media only screen and (width <= 1720px) {
  .select.six {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (width <= 640px) {
  .select.six {
    grid-template-columns: 1fr 1fr;
  }
}

.select + .select {
  margin-top: 2rem;
}

@media only screen and (width <= 768px) {
  .select + .select {
    margin-top: 1rem;
  }
}

.step-title {
  text-align: center;
  margin: 3rem 0;
}

@media only screen and (width <= 768px) {
  .step-title {
    margin: 1rem 0;
  }
}

.symbol-wrapper {
  justify-items: center;
  display: grid;
}

.symbol-wrapper.inline {
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  display: inline;
}

.symbol-wrapper.inline span {
  margin-right: .5rem;
}

img.symbol {
  width: 5rem;
  height: 5rem;
}

@media (prefers-color-scheme: dark) {
  img.symbol:not(.final) {
    filter: invert(.5) sepia(80) saturate(0) hue-rotate(15deg);
  }
}

@media only screen and (width <= 768px) {
  img.symbol {
    width: 3rem;
    height: 3rem;
  }
}

img.symbol.inline {
  width: 2.6rem;
  height: 2.6rem;
  padding: 0 .5rem;
}

@media only screen and (width <= 1024px) {
  img.symbol.inline {
    width: 2.6rem;
    height: 2.6rem;
  }
}

@media only screen and (width <= 768px) {
  img.symbol.inline {
    width: 1.6rem;
    height: 1.6rem;
    padding-top: .5rem;
  }
}

@media only screen and (width <= 640px) {
  img.symbol.inline {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.text {
  margin-top: 1rem;
}

.text.disabled, img.text.symbol.disabled {
  color: #444;
}

.hide-symbol + .text {
  border: .2rem solid #d3d3d3;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.triangle {
  border: 16rem solid #0000;
  border-top-width: 0;
  border-bottom: 22.6274rem solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
  line-height: 22.6274rem;
}

@media only screen and (width <= 1024px) {
  .triangle {
    border: 12rem solid #0000;
    border-top-width: 0;
    border-bottom: 16.9706rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
    line-height: 16.9706rem;
  }
}

@media only screen and (width <= 768px) {
  .triangle {
    border: 6rem solid #0000;
    border-top-width: 0;
    border-bottom: 8.48528rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
    line-height: 8.48528rem;
  }
}

.triangle.small {
  border: 8rem solid #0000;
  border-top-width: 0;
  border-bottom: 11.3137rem solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
  line-height: 11.3137rem;
}

@media only screen and (width <= 1024px) {
  .triangle.small {
    border: 6rem solid #0000;
    border-top-width: 0;
    border-bottom: 8.48528rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
    line-height: 8.48528rem;
  }
}

@media only screen and (width <= 768px) {
  .triangle.small {
    border: 4rem solid #0000;
    border-top-width: 0;
    border-bottom: 5.65685rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
    line-height: 5.65685rem;
  }
}

.triangle.tiny {
  border: 4rem solid #0000;
  border-top-width: 0;
  border-bottom: 5.65685rem solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
  line-height: 5.65685rem;
}

@media only screen and (width <= 1024px) {
  .triangle.tiny {
    border: 2rem solid #0000;
    border-top-width: 0;
    border-bottom: 2.82843rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
    line-height: 2.82843rem;
  }
}

.square {
  border: 10rem solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
}

@media only screen and (width <= 1024px) {
  .square {
    border: 8rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

@media only screen and (width <= 768px) {
  .square {
    border: 5rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

.square.small {
  border: 8rem solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
}

@media only screen and (width <= 1024px) {
  .square.small {
    border: 5rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

@media only screen and (width <= 768px) {
  .square.small {
    border: 4rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

.square.tiny {
  border: 4rem solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
}

@media only screen and (width <= 1024px) {
  .square.tiny {
    border: 2rem solid #000;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

.circle {
  border: 10rem solid #000;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0 auto;
}

@media only screen and (width <= 1024px) {
  .circle {
    border: 8rem solid #000;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

@media only screen and (width <= 768px) {
  .circle {
    border: 5rem solid #000;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

.circle.small {
  border: 8rem solid #000;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0 auto;
}

@media only screen and (width <= 1024px) {
  .circle.small {
    border: 5rem solid #000;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

@media only screen and (width <= 768px) {
  .circle.small {
    border: 4rem solid #000;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

.circle.tiny {
  border: 4rem solid #000;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0 auto;
}

@media only screen and (width <= 1024px) {
  .circle.tiny {
    border: 2rem solid #000;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
}

@keyframes PendingNewShape {
  0% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(480deg);
  }

  100% {
    transform: rotate(240deg);
  }
}

.manual-highlight, .statue-shape-symbol:hover, .statue-symbol:hover {
  filter: drop-shadow(0 0 .4rem #d37200);
  cursor: pointer;
}

.empty-stack {
  aspect-ratio: 1;
  clip-path: polygon(100% 50%, 90.57% 60.87%, 93.3% 75%, 79.7% 79.7%, 75% 93.3%, 60.87% 90.57%, 50% 100%, 39.13% 90.57%, 25% 93.3%, 20.3% 79.7%, 6.7% 75%, 9.43% 60.87%, 0% 50%, 9.43% 39.13%, 6.7% 25%, 20.3% 20.3%, 25% 6.7%, 39.13% 9.43%, 50% 0%, 60.87% 9.43%, 75% 6.7%, 79.7% 20.3%, 93.3% 25%, 90.57% 39.13%);
  background-color: #000;
  width: 15rem;
  margin: 0 auto;
  animation: 3s linear infinite PendingNewShape;
}

@media only screen and (width <= 1024px) {
  .empty-stack {
    width: 12rem;
  }
}

@media only screen and (width <= 768px) {
  .empty-stack {
    width: 8rem;
  }
}

.buff {
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

.buff-icon {
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  box-sizing: border-box;
  background-color: #75a681;
  width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
  position: relative;
}

.buff-icon + span {
  margin-left: 1rem;
}

.buff-icon .inner {
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  background-color: #3f4034;
  background-position: 50% 45%;
  background-repeat: no-repeat;
  background-size: 80%;
  justify-content: center;
  align-items: center;
  width: 4.1rem;
  height: 4.1rem;
  display: flex;
  position: absolute;
  top: .2rem;
  left: .2rem;
}

.buff-icon .inner img {
  filter: invert(.5) sepia(80) saturate(0) hue-rotate(15deg);
  width: 55%;
  height: 55%;
  padding-top: .5rem;
  position: relative;
}

.instruction-list {
  grid-column: 1 / 4;
  margin: 0 10rem;
  font-size: 2rem;
  list-style: decimal inside;
}

@media only screen and (width <= 1024px) {
  .instruction-list {
    margin: 0 1rem;
  }
}

@media only screen and (width <= 640px) {
  .instruction-list {
    margin: 0;
    font-size: 1.4rem;
  }
}

.instruction-list li {
  margin-bottom: 1rem;
}

@media only screen and (width <= 768px) {
  .instruction-list li {
    margin-left: 1rem;
  }
}

.instruction-list li > div {
  display: inline;
}

.instruction-list > div {
  padding: 1rem 0;
}

.instruction-solution {
  border-top: .3rem solid #000;
  border-bottom: .3rem solid #000;
  margin: .5rem 0;
  display: flex;
}

@media (prefers-color-scheme: dark) {
  .instruction-solution {
    border-color: #444;
  }
}

@media only screen and (width <= 768px) {
  .instruction-solution {
    border-top-width: .1rem;
    border-bottom-width: .1rem;
    margin: .5rem 1rem;
  }

  .instruction-solution .symbol-wrapper.inline span {
    display: block;
  }

  .instruction-solution-side {
    text-align: center;
    flex-grow: 1;
  }

  .instruction-solution-side .symbol-wrapper.inline, .instruction-solution-side .symbol-wrapper.inline span {
    margin-right: 0;
  }
}

.stack {
  height: 20rem;
  display: block;
}

@media only screen and (width <= 768px) {
  .stack {
    height: 10rem;
  }
}

@media only screen and (width <= 640px) {
  .stack {
    height: 10rem;
  }
}

@keyframes SymbolTransition {
  5%, 95% {
    transform: scale(1);
  }

  0%, 100% {
    transform: scale(.01);
  }
}

.reset-main {
  justify-content: center;
  margin-bottom: 1rem;
  display: flex;
}

.stack-display.rotate {
  animation: 1.5s linear infinite SymbolTransition;
}

.statue-list {
  grid-row-gap: 5rem;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.statue-list > :nth-child(2) {
  order: 3;
  grid-column: 3 / 1;
}

@media only screen and (width <= 640px) {
  .statue-list {
    margin-top: 1rem;
  }
}

.statue-wrapper {
  justify-content: center;
  justify-items: center;
  row-gap: .5rem;
  display: grid;
  position: relative;
}

.statue-wrapper > div {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.statue-wrapper.triangle-statue span.player-marker {
  position: absolute;
  top: 50%;
}

.statue-wrapper .player-marker {
  color: #fff;
}

.statue-wrapper > button {
  margin-top: 2rem;
}

.knight-options {
  justify-content: space-around;
  margin-bottom: 1rem;
  display: flex;
}

.knight-options > div {
  position: relative;
}

.ogre-option, .escape-option {
  justify-content: space-around;
  margin-bottom: 1rem;
  display: flex;
}

.simulator-data {
  z-index: 1;
  position: absolute;
  right: 0;
}

@keyframes FinalizedTransition {
  0% {
    background-color: #dd282800;
  }

  100% {
    background-color: #0a0a0af2;
  }
}

.finalized-overlay {
  color: #fff;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: 2.5s linear forwards FinalizedTransition;
  display: grid;
  position: fixed;
  top: 0;
}

.finalized-overlay span, .success-data {
  text-align: center;
}

.tutorial-option {
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  font-size: 1.2rem;
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.tutorial-option:hover {
  cursor: pointer;
  color: #d37200;
  border-color: #d37200;
  transition: all .2s ease-in-out;
}

.tutorial-wrapper {
  border: 1px solid #fff;
  max-width: 116rem;
  margin: 0 auto 2rem;
  padding: 2rem;
  position: relative;
}

.tutorial-wrapper h2 {
  text-align: center;
}

.tutorial-wrapper ul {
  list-style: circle inside;
}

.tutorial-controls {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tutorial-progress {
  text-align: center;
  width: 80%;
}

.prev-step, .next-step {
  width: 10%;
}

.close-tutorial {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@keyframes PointerArrowBounceTop {
  0%, 100% {
    top: 3.5rem;
  }

  50% {
    top: 4.5rem;
  }
}

@keyframes PointerArrowBounceBottom {
  0%, 100% {
    top: -2rem;
  }

  50% {
    top: -1rem;
  }
}

@keyframes PointerArrowBounceLeft {
  0%, 100% {
    left: 2rem;
  }

  50% {
    left: 1rem;
  }
}

@keyframes PointerArrowBounceRight {
  0%, 100% {
    left: -2rem;
  }

  50% {
    left: -1rem;
  }
}

@keyframes PointerArrowBounceBottomAbs {
  0%, 100% {
    top: -5.5rem;
  }

  50% {
    top: -6.5rem;
  }
}

.tutorial-pointer-arrow {
  background-color: red;
}

.tutorial-pointer-arrow.absolute {
  position: absolute;
}

.tutorial-pointer-arrow.relative {
  position: relative;
}

.tutorial-pointer-arrow.center {
  margin: 0 auto;
}

.tutorial-pointer-arrow.left {
  clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
  width: 3.5rem;
  height: 2.5rem;
  animation: 1.5s ease-in-out infinite PointerArrowBounceLeft;
  left: 4rem;
}

.tutorial-pointer-arrow.up {
  clip-path: polygon(20% 100%, 20% 40%, 0 40%, 50% 0, 100% 40%, 80% 40%, 80% 100%);
  width: 2.5rem;
  height: 3.5rem;
  animation: 1.5s ease-in-out infinite PointerArrowBounceTop;
}

.tutorial-pointer-arrow.right {
  clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
  width: 3.5rem;
  height: 2.5rem;
  animation: 1.5s ease-in-out infinite PointerArrowBounceRight;
  right: 4rem;
}

.tutorial-pointer-arrow.down {
  clip-path: polygon(20% 0, 20% 60%, 0 60%, 50% 100%, 100% 60%, 80% 60%, 80% 0);
  width: 2.5rem;
  height: 3.5rem;
  animation: 1.5s ease-in-out infinite PointerArrowBounceBottom;
}

.tutorial-pointer-arrow.down.absolute {
  animation: 1.5s ease-in-out infinite PointerArrowBounceBottomAbs;
}

.tutorial-pointer-arrow.inline {
  animation: none;
  display: inline-block;
  position: relative;
}

.input-history {
  text-align: right;
  direction: rtl;
  width: 27.5%;
  height: 14rem;
  position: fixed;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}

@media only screen and (width <= 1024px) {
  .input-history {
    text-align: center;
    direction: ltr;
    width: 100%;
    margin-top: 2rem;
    position: relative;
  }
}

.input-history .input-row {
  direction: ltr;
  justify-content: end;
  align-items: center;
  display: flex;
}

.input-history .input-row:hover {
  cursor: pointer;
}

.input-history .input-row.green {
  color: green;
}

.input-history .input-row.yellow {
  color: #ff0;
}

.input-history .input-row.red {
  color: red;
}

.input-history img {
  width: 2rem;
  height: 2rem;
}

.input-history img.input-success-state-icon {
  margin-left: 1rem;
}

.input-history img.input-replay-icon {
  margin-right: 1rem;
}

.input-history img.red {
  filter: invert(15%) sepia(36%) saturate(5098%) hue-rotate(348deg) brightness(99%) contrast(121%);
}

.input-history img.green {
  filter: invert(31%) sepia(98%) saturate(1257%) hue-rotate(93deg) brightness(99%) contrast(106%);
}

.input-history img.yellow {
  filter: invert(89%) sepia(93%) saturate(6585%) hue-rotate(358deg) brightness(106%) contrast(107%);
}

.input-history-overlay {
  display: flex;
  position: absolute;
}

.input-history-overlay .overlay-symbol {
  width: 8rem;
  height: 8rem;
}

@media only screen and (width <= 768px) {
  .input-history-overlay .overlay-symbol {
    width: 3rem;
    height: 3rem;
  }
}

@media (prefers-color-scheme: dark) {
  .input-history-overlay .overlay-symbol {
    filter: invert(.5) sepia(80) saturate(0) hue-rotate(15deg);
  }
}

.input-history-overlay.from-left.to-middle {
  margin-top: 10%;
  margin-left: 35%;
}

.input-history-overlay.from-left.to-right {
  margin-top: 4%;
  margin-left: 35%;
}

.input-history-overlay.from-middle.to-left {
  margin-top: 10%;
  margin-left: 35%;
}

.input-history-overlay.from-middle.to-right {
  margin-top: 10%;
  margin-left: 60%;
}

.input-history-overlay.from-right.to-left {
  margin-top: 4%;
  margin-left: 60%;
}

.input-history-overlay.from-right.to-middle {
  margin-top: 10%;
  margin-left: 60%;
}

.overlay-pointer-arrow {
  clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
  background-color: #ff0;
  width: 6.5rem;
  height: 4.5rem;
  margin: 0 auto;
  position: absolute;
  bottom: -6rem;
}

@media only screen and (width <= 768px) {
  .overlay-pointer-arrow {
    width: 5rem;
    height: 3.5rem;
    bottom: -4rem;
  }
}

.overlay-pointer-arrow.from-left.to-middle {
  transform: rotate(215deg);
}

.overlay-pointer-arrow.from-left.to-right {
  transform: rotate(180deg);
}

.overlay-pointer-arrow.from-middle.to-left {
  transform: rotate(45deg);
}

.overlay-pointer-arrow.from-middle.to-right {
  transform: rotate(135deg);
}

.overlay-pointer-arrow.from-right.to-left {
  transform: rotate(0);
}

.overlay-pointer-arrow.from-right.to-middle {
  transform: rotate(-45deg);
}

.overlay-success-state {
  width: 3rem;
  height: 3rem;
  position: absolute;
  bottom: 100%;
  left: calc(50% - 1.5rem);
}

.overlay-success-state.red {
  filter: invert(15%) sepia(36%) saturate(5098%) hue-rotate(348deg) brightness(99%) contrast(121%);
}

.overlay-success-state.green {
  filter: invert(31%) sepia(98%) saturate(1257%) hue-rotate(93deg) brightness(99%) contrast(106%);
}

.simulator-checklist {
  justify-content: space-between;
  align-items: flex-end;
  max-width: 100rem;
  margin: 1rem auto;
  display: flex;
  position: static;
}

@media only screen and (width <= 640px) {
  .simulator-checklist {
    max-width: 50rem;
  }
}

@media only screen and (width <= 480px) {
  .simulator-checklist {
    margin: 1rem;
  }
}

.simulator-checklist > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.simulator-checklist > div > span {
  text-align: center;
}

.simulator-checklist img {
  width: 2rem;
  height: 2rem;
}

.simulator-checklist img.red {
  filter: invert(15%) sepia(36%) saturate(5098%) hue-rotate(348deg) brightness(99%) contrast(121%);
}

.simulator-checklist img.green {
  filter: invert(31%) sepia(98%) saturate(1257%) hue-rotate(93deg) brightness(99%) contrast(106%);
}

.mode-notification {
  text-align: center;
}

.countdown-timer {
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
  display: inline-flex;
  position: sticky;
  top: 1rem;
}

@media only screen and (width <= 640px) {
  .countdown-timer {
    margin-bottom: 1rem;
  }
}

.countdown-timer .time {
  width: 4rem;
  display: inline;
}

.countdown-timer .descriptor {
  mix-blend-mode: exclusion;
  margin-left: 1rem;
}

.imminent-end-icon {
  clip-path: polygon(50% 100%, 100% 62%, 82% 0%, 18% 0%, 0% 62%);
  box-sizing: border-box;
  background-color: #cf5400;
  width: 3.5rem;
  height: 3.5rem;
  display: inline-block;
  position: relative;
}

.imminent-end-icon .inner {
  clip-path: polygon(50% 100%, 100% 62%, 82% 0%, 18% 0%, 0% 62%);
  background-color: #4e2924;
  background-image: url("stopwatch.7fe73332.svg");
  background-position: 50% 45%;
  background-repeat: no-repeat;
  background-size: 60%;
  width: 3.1rem;
  height: 3.1rem;
  position: absolute;
  top: .2rem;
  left: .2rem;
}

@keyframes HighlightPulse {
  0%, 100% {
    filter: drop-shadow(0 0 .3rem #d37200);
  }

  50% {
    filter: drop-shadow(0 0 .7rem #d37200);
  }
}

.highlight-pulse {
  animation: 1.5s linear infinite HighlightPulse;
}
/*# sourceMappingURL=index.2e61e57a.css.map */
