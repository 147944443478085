.select-title {
  margin: 3rem 0;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: $media-screen-md) {
    margin: 0.5rem 0;
  }
}

.select {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0;
  row-gap: 1rem;
  min-height: 5rem;

  &.three {
    @media only screen and (max-width: $media-screen-md) {
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      & .symbol-wrapper:nth-child(2) {
        order: 3;
        grid-column-start: 3;
        grid-column-end: 1;
      }

      & .symbol-wrapper:nth-child(3) {
        order: 2;
      }
    }
  }

  &.six {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: $media-screen-xx-lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: $media-screen-sm) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.select + .select {
  margin-top: 2rem;

  @media only screen and (max-width: $media-screen-md) {
    margin-top: 1rem;
  }
}
