.selectable {
  cursor: pointer;
}

.default-filter {
  @media (prefers-color-scheme: dark) {
    filter: invert(0.5) sepia(80) saturate(0) hue-rotate(15deg);
  }

  &:hover {
    filter: brightness(0) saturate(100%) invert(64%) sepia(51%) saturate(7500%)
      hue-rotate(15deg) brightness(100%) contrast(101%);
    transition: filter 0.2s ease-in-out;
  }
}

.selected {
  filter: invert(0.5) sepia(80) saturate(45) hue-rotate(15deg);

  @media (prefers-color-scheme: dark) {
    filter: brightness(0) saturate(100%) invert(64%) sepia(51%) saturate(7500%)
      hue-rotate(15deg) brightness(100%) contrast(101%);
  }
}

.disabled {
  cursor: not-allowed;
  filter: invert(0.5) sepia(80) saturate(0) hue-rotate(15deg);

  @media (prefers-color-scheme: dark) {
    filter: invert(0.25) sepia(0) saturate(0) hue-rotate(15deg);
  }
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.final {
  filter: invert(0.5) sepia(10) saturate(55) hue-rotate(185deg);
}

.hide-text {
  display: none;
}

.hide-symbol {
  display: none;
}

.text-rotate {
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
  height: calc(100% - 2rem);

  // Some browsers don't support writing-mode: sideways-lr, this achieves the same effect
  transform: rotate(180deg);
}
