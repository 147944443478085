.side-wrapper {
  display: grid;
  grid-template-columns: 33% 33% 33%;

  @media only screen and (max-width: $media-screen-md) {
    grid-template-columns: 33% 33% 33%;
  }
}

.side {
  border-left: solid 0.3rem black;
  margin-bottom: 2rem;
  justify-self: justify;

  @media (prefers-color-scheme: dark) {
    border-color: #444;
  }

  @media only screen and (max-width: $media-screen-md) {
    border-left-width: 0.1rem;
  }

  &:first-of-type {
    border-left: none;
  }
}

.side-title {
  margin: 3rem 0;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: $media-screen-md) {
    margin: 1rem 0;
  }
}
