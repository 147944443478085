.countdown-timer {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 10;
  margin-bottom: 4rem;
  top: 1rem;

  @media only screen and (max-width: $media-screen-sm) {
    margin-bottom: 1rem;
  }

  & .time {
    width: 4rem;
    display: inline;
  }

  & .descriptor {
    margin-left: 1rem;
    mix-blend-mode: exclusion;
  }
}

.imminent-end-icon {
  width: 3.5rem;
  height: 3.5rem;
  clip-path: polygon(50% 100%, 100% 62%, 82% 0%, 18% 0%, 0% 62%);
  background-color: #cf5400;
  display: inline-block;
  position: relative;
  box-sizing: border-box;

  & .inner {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    width: 3.1rem;
    height: 3.1rem;
    clip-path: polygon(50% 100%, 100% 62%, 82% 0%, 18% 0%, 0% 62%);
    background-color: #4e2924;
    background-image: url("/src/assets/images/stopwatch.svg");
    background-repeat: no-repeat;
    background-position: center 45%;
    background-size: 60%;
  }
}
