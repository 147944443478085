// .step-wrapper {
//   display: flex;
//   flex-direction: column;
// }

// .step-wrapper {
//   display: grid;
//   grid-template-columns: 25% 45%;
//   justify-content: center;
//   align-items: center;
//   flex-direction: row;

//   &.hide-text {
//     grid-template-columns: 0% 100%;
//   }

//   & .symbol-wrapper {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     align-items: center;

//     & .text {
//       margin-top: 0;
//     }
//   }
// }

.step-title {
  margin: 3rem 0;
  text-align: center;

  @media only screen and (max-width: $media-screen-md) {
    margin: 1rem 0;
  }
}
