.instruction-solution {
  border-top: solid 0.3rem black;
  border-bottom: solid 0.3rem black;
  margin: 0.5rem 0;
  display: flex;

  @media (prefers-color-scheme: dark) {
    border-color: #444;
  }

  @media only screen and (max-width: $media-screen-md) {
    border-top-width: 0.1rem;
    border-bottom-width: 0.1rem;
    margin: 0.5rem 1rem;
  }

  & .symbol-wrapper.inline {
    & span {
      @media only screen and (max-width: $media-screen-md) {
        display: block;
      }
    }
  }
}

.instruction-solution-side {
  @media only screen and (max-width: $media-screen-md) {
    flex-grow: 1;
    text-align: center;
  }

  & .symbol-wrapper.inline {
    @media only screen and (max-width: $media-screen-md) {
      margin-right: 0;

      & span {
        margin-right: 0;
      }
    }
  }
}
