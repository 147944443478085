header {
  // display: flex;
  // justify-items: center;
  // align-items: center;
  // width: 100%;

  margin: 1rem;

  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;

  & menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;

    & li {
      margin-left: 1rem;

      & img.symbol.inline {
        width: 2.2rem;
        height: 2.2rem;
        padding: 0;

        &:hover {
          filter: invert(0.5) sepia(80) saturate(45) hue-rotate(15deg);
          transition: filter 0.2s ease-in-out;
        }

        @media only screen and (max-width: $media-screen-md) {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }

  & .control-panel {
    grid-column: 1/3;
  }
}

.title-wrapper {
  // margin: 2rem 0;
  // width: 100%;
  // text-align: center;

  // @media only screen and (max-width: $media-screen-md) {
  //   margin: 1rem 0;
  // }

  h1 {
    font-weight: 900;
  }

  & .atlas {
    font-style: italic;
    font-weight: 100;
  }

  & h2 {
    font-size: 1.2rem;
    font-weight: 200;
  }
}
