button {
  background-color: white;
  cursor: pointer;
  border: solid 0.1rem black;
  padding: 0.6rem 1rem;
  border-radius: 0.25rem;
  font-family: "Jost", Calibri, "Trebuchet MS", sans-serif;

  &:hover {
    border-color: $hover-color;
    transition: border-color 0.2s ease-in-out;
  }

  @media (prefers-color-scheme: dark) {
    background-color: #222;
    border-color: #444;
    color: lightgrey;
  }
}

select {
  @media (prefers-color-scheme: dark) {
    background-color: #444;
    color: lightgrey;
  }
}
